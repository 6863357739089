import React from 'react';
import './App.css';

function result_display(result, index) {
  // Convert the timestamp from minutes:seconds to seconds
  const timestamp_seconds = String(parseInt(result.timestamp.split(":")[0]) * 60 + parseInt(result.timestamp.split(":")[1]))
  return (
    <div className="mb-1" key={index}>
      <h3 className="text-m font-bold">{index}. {result.episode} - {result.title}</h3>
      <p className="text-sm">{result.text}</p>
      <p className="text-sm">Timestamp: {result.timestamp} <a className="font-medium text-red-600 underline dark:text-red-500 hover:no-underline" href={"https://youtube.com/watch?v=" + result.youtube_url + "&t=" + timestamp_seconds}>Youtube Link</a> <audio src={result.audio_url + "#t=" + timestamp_seconds} controls/> </p>
    </div>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: '',
      results: [],
      show: 'The Yard',
      options: ['The Yard', 'Fear And', 'Wine About It', 'Chuckle Sandwich', 'Safety Third', 'Sweet N Sour', "Lemonade Stand"]
    };
    this.getSearch = this.getSearch.bind(this);
    this.handlePodcastChange = this.handlePodcastChange.bind(this);
    this.handlePromptChange = this.handlePromptChange.bind(this);
  }

  handlePromptChange(event) {
    this.setState({prompt: event.target.value});
  }

  handlePodcastChange(event) {
    this.setState({show: event.target.value});
  }

  getSearch() {
    fetch('/search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({prompt: this.state.prompt, show: this.state.show})
    })
    .then(response => response.json())
    .then(data => this.setState({results: data}));
  }

  render() {
    return (
      <div className="m-auto w-9/12 pt-5">
        <h1 className="text-3xl text-center font-bold">Podcast Search</h1>
        <p className="text-sm">Semantic Search through The Yard transcripts to find bits and content that you can't otherwise find. You don't have to know the exact words used, unlike with normal transcript search. Instead, you can search for concepts and ideas.</p>
        <p className="text-sm">New! I've add Fear&amp; transcripts! You can search through either show now.</p>
        <p className="text-sm">Newer! I've added Wine About It and Chuckle Sandwich And Safety Third (12/9/23)</p>
        <p className="text-sm">Newer! I've added Sweet N Sour and Podcast The Ride (1/13/25)</p>
        <p className="text-sm">Newer! I've added Lemonade Stand (3/8/25)</p>
        <p className="text-center"><textarea className="p-4 text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" rows="4" cols="50" value={this.state.prompt} onChange={this.handlePromptChange} placeholder="Write your search query here..." /></p>
        <p className="text-center"><select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 px-5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={this.state.show} onChange={this.handlePodcastChange}>{this.state.options.map((option) => <option key={option} value={option}>{option}</option>)}</select></p>
        <p className="text-center"><button className="text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 my-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={this.getSearch}>Search</button></p>
        {(this.state.results.length !== 0) &&
          <div className="divide-y-2 divide-slate-400 divide-solid">
          {this.state.results.map((result, i) => (result_display(result, i+1)))}
          </div>
        }
        <div className="pt-5">
          <p className="text-xs">Created by <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.x.com/thelovinator">Nathaniel Lovin</a>.</p>
          <p className="text-xs">Unoffical fan-made site. You can find The Yard on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/@TheYardPodcast">Youtube</a> or <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.x.com/TheYard">Twitter</a>. You can find Fear&amp; on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="http://youtube.com/@FearAndPodcast">Youtube</a> and <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="http://x.com/FearAndPod">Twitter</a>. You can find Chuckle Sandwich on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/c/chucklesandwich">Youtube</a> and <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="http://x.com/chucklesandwich">Twitter</a>. You can find Wine About It on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/@WineAboutItPodcast">Youtube</a> and <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="http://x.com/wineaboutit69">Twitter</a>. You can find Safety Third on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/@safetythird">Youtube</a> and <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="http://x.com/SafetyThirdPod">Twitter</a>. 
          You can find Sweet n Sour on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/@SweetnSour">Youtube</a> or <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.x.com/sweetnsour_pod">Twitter</a>. You can find Lemonade Stand on <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.youtube.com/@LemonadeStandPodcast">Youtube</a> or <a className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline" href="https://www.x.com/LemonadeCast">Twitter</a>.
           </p>
        </div>
      </div>
    );
  }

}

export default App;
